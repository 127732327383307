<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AppHeader/>

  <UMain>
    <slot/>
  </UMain>

  <AppFooter/>

  <UNotifications/>
</template>
